
const AllAppRoutes = {
    dashboard: "/dashboard",
    all_videos: "/videos/all",
    all_users: "/users/all",
    user_detail: "/user/:userId",
    user_cart: "/user/carts",
    all_video_purchases: "/video-purchases/all",
    all_virtual_classes: "/virtual-classes/all",
    all_product_orders: "/product-orders/all",
    all_physical_orders: "/physical-orders/all",
    all_product_orders: "/product-orders/all",
    all_physical_classes: "/physical-classes/all",
    all_products: "/products/all",
    all_weekly_users: "/users/weekly/all",

    video_analytics: "/videos/analytics/:id",
}

export default AllAppRoutes;