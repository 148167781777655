import React, { useState, useEffect } from "react";

import { Input, Spin, Table, notification, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import * as yup from 'yup';
import { DateTime } from "luxon";
import { Controller, set, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { _fetch_class_data, _fetch_videos_data, _save_class_data } from "../utils/axios-routes";
import { Link, useNavigate } from "react-router-dom";


import _1 from "../assets/images/_2.jpg";
import Sidenav from "../components/sidenav";
import TopBar from "../components/topbar";

const VideosPage = () => {

    const formValidator = yup.object().shape({
        className: yup.string().required('Please enter class name')
    })
    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(formValidator)
    });

    const antIcon = (<LoadingOutlined style={{ fontSize: 24 }} spin />);
    const Navigate = useNavigate();

    const [displayList, setDisplayList] = useState(true);
    const [savingData, setSavingData] = useState(false);
    const [fetchingData, setFetchingData] = useState(true);
    const [videoData, setVideoData] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loadingData, setLoadingData] = useState(true);

    const openNotification = err => {
        notification.open({
            description: err
        });
    }

    useEffect(() => {
        if (localStorage.getItem('newDataSaved')) {
            openNotification('Data saved successfully.');
            localStorage.removeItem('newDataSaved');
        };
        let getAllVideoData = async () => {
            try {
                let fetchVideoData = await _fetch_videos_data();
                console.log(fetchVideoData)
                if (fetchVideoData.data.statusMessage === "success") {
                    setFetchingData(false);
                    setVideoData(fetchVideoData.data.message);
                }
                setLoadingData(false)
            } catch (err) {
                openNotification('An error occurred while fetching videos data. Please reload to try again.');
                setLoadingData(false)
            }
        }
        getAllVideoData();
    }, [])

    const columns = [
        {
            title: '',
            dataIndex: 'id',
        },
        {
            title: '',
            dataIndex: 'poster',
        },
        {
            title: 'Title',
            className: 'column-money',
            dataIndex: 'title',
        },
        {
            title: 'Category',
            className: 'column-money',
            dataIndex: 'videoCategory',
        },
        {
            title: 'Counter',
            dataIndex: 'counter',
        },
        {
            title: 'Purchases',
            dataIndex: 'purchases',
        },
        {
            title: 'Ratings',
            dataIndex: 'ratings',
        },
        {
            title: 'Date added',
            dataIndex: 'dateAdded',
        },
        {
            title: '',
            dataIndex: 'editAction',
        },
        // {
        //     title: '',
        //     dataIndex: 'deleteAction',
        // },
    ];
    const data = videoData.map((videosDatum, index) => {
        return {
            key: index,
            id: index + 1,
            poster: <img src={videosDatum.poster} alt={videosDatum.title} style={{ width: 100, height: 'auto' }} />,
            title: videosDatum.title,
            videoCategory: videosDatum.videoCategory.name,
            counter: videosDatum.counter,
            purchases: videosDatum.videoPurchases.length,
            ratings: videosDatum.videoRatings.length,
            dateAdded: DateTime.fromISO(videosDatum.createdAt).toFormat('MMMM dd, yyyy'),
            editAction: <Link to={`/videos/analytics/${videosDatum._id}`} className="btn-blue">View</Link>,
            deleteAction: <button onClick={() => setIsModalOpen(true)} className="btn-red">Delete</button>
        }
    });

    const saveClass = async e => {
        setErrorMessage('');
        const { className } = e;
        let newvideoData = {
            className
        };
        setSavingData(true);
        try {
            let saveNewClass = await _save_class_data(newvideoData);
            if (saveNewClass.data.statusMessage === "success") {
                setSavingData(false);
                localStorage.setItem('newDataSaved', true);
                Navigate(0);
            } else {
                let errorMessage = saveNewClass.data.summary === "Class name already saved. Please check and try again" ?
                    saveNewClass.data.summary : "An error occurred while trying to save class. Please try again.";
                setSavingData(false);
                setErrorMessage(errorMessage);
                openNotification(errorMessage);
            }
        } catch (err) {
            setSavingData(false);
            setErrorMessage('An error occurred while trying to save class. Please try again.');
            openNotification('An error occurred while trying to save class. Please try again.');
        }
    }
    return (
        <Spin indicator={antIcon} spinning={loadingData}>
            <div>
                <div>
                    <Sidenav />
                </div>
                <div className="main-display">
                    <div>
                        <TopBar />
                    </div>
                    <div>
                        <div className="main-display-content">
                            <div>
                                <div className="grid-flex page-header">
                                    <h3>Videos</h3>
                                    <div>
                                        {
                                            displayList ?
                                                <button
                                                    onClick={() => setDisplayList(false)}
                                                    className="btn-blue">Add New Video</button>
                                                :
                                                <button
                                                    onClick={() => setDisplayList(true)}
                                                    className="btn-blue">See All Videos</button>
                                        }
                                    </div>
                                </div>
                                <div className="">
                                    <div>
                                        <div className="content-box mb-4">
                                            {
                                                displayList ?
                                                    <React.Fragment>
                                                        {
                                                            !fetchingData ?
                                                                <React.Fragment>
                                                                    <div className="content-box-header">
                                                                        <div className="grid-flex">
                                                                            <h3>Recently Added Videos</h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="content-box-body">
                                                                        <div>
                                                                            <Table
                                                                                columns={columns}
                                                                                dataSource={data}
                                                                                bordered
                                                                                title={null}
                                                                                footer={null}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                                :
                                                                <LoadingOutlined style={{ fontSize: '2rem' }} />
                                                        }
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <div className="content-box-header">
                                                            <div className="grid-flex">
                                                                <h3>Add New Class</h3>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="width-60">
                                                                {
                                                                    errorMessage ?
                                                                        <div className="error-block">
                                                                            <p className="error-message">{errorMessage}</p>
                                                                        </div> : ''
                                                                }
                                                                <form onSubmit={handleSubmit(saveClass)}>
                                                                    <div className="form-group">
                                                                        <label htmlFor="className">Class name</label>
                                                                        <Controller control={control} defaultValue="" name="className"
                                                                            render={({ field }) => (
                                                                                <Input
                                                                                    style={{ height: '5rem' }} {...field}
                                                                                    name="className" id="className" type="text" />
                                                                            )} />
                                                                        {errors.className && <p className="error-message">{errors.className.message}</p>}
                                                                    </div>
                                                                    {
                                                                        !savingData ?
                                                                            <button className="btn-blue">Save Class</button>
                                                                            :
                                                                            <button className="btn-blue" disabled><LoadingOutlined style={{ fontSize: '2rem' }} /></button>
                                                                    }
                                                                </form>
                                                                {/* <img src={_1} alt="" /> */}
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="delete-button" title={null} open={isModalOpen} footer={null}>
                    <h3>Are you sure you want to delete this class? This action can not be reversed!</h3>
                    <div className="grid-flex">
                        <div></div>
                        <div className="grid-button">
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="btn-default">Cancel</button>
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="btn-red">Delete</button>
                        </div>
                    </div>
                </Modal>
            </div>
        </Spin>
    )
}

export default VideosPage;