import React from "react";
import { Link } from "react-router-dom";

import { Input } from "antd";

import ExternalLink from "../assets/images/external-link.svg";
import Magnifier from "../assets/images/magnifier.svg";
import LogOut from "../assets/images/logout.svg";
import Bell from "../assets/images/bell.svg";

const TopBar = () => {

    const onSearch = () => console.log('check me');

    return (
        <div className="topbar">
            <div className="grid-flex">
                <div>
                    <div
                    style={{width: '30%'}}
                    >
                        <div className="form-group-div">
                            <Input style={{ height: '4.5rem' }} />
                            <div className="form-group-icon">
                                <img src={Magnifier} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <ul className="grid-flex-div">
                        <li>
                            <Link to=""><img src={ExternalLink} alt="" /></Link>
                        </li>
                        <li>
                            <Link to=""><img src={Bell} alt="" /></Link>
                        </li>
                        <li>
                            <Link to="/signout"><img src={LogOut} alt="" /></Link>
                        </li>
                        <li>
                            <div className="avatar-sect"></div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default TopBar;