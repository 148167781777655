import React, { useEffect, useState } from "react";

import { Divider, Spin, Table, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { _fetch_notes_data, _fetch_students_data, _fetch_quiz_data, _fetch_users_data, _fetch_videos_data, _fetch_all_video_purchases } from "../utils/axios-routes";

import _1 from "../assets/images/_1.jpg";
import Sidenav from "../components/sidenav";
import TopBar from "../components/topbar";
import { ReactComponent as ExternalNavLink } from "../assets/images/external-link.svg";
import AllAppRoutes from "../utils/routes";

const Homepage = () => {

    const antIcon = (<LoadingOutlined style={{ fontSize: 24 }} spin />);
    const openNotification = err => {
        notification.open({
            description: err
        });
    }

    const [videoPurchaseData, setVideoPurchaseData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [videoData, setVideoData] = useState([]);
    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {
        let getAllVideoPurchasesData = async () => {
            try {
                let fetchVideoPurchaseData = await _fetch_all_video_purchases();
                if (fetchVideoPurchaseData.data.statusMessage === "success") {
                    setVideoPurchaseData(fetchVideoPurchaseData.data.message);
                }
            } catch (err) {
                openNotification('An error occurred while fetching notes data. Please reload to try again.');
            }
        }
        let getAllUsersData = async () => {
            try {
                let fetchUserData = await _fetch_users_data();
                if (fetchUserData.data.statusMessage === "success") {
                    setUserData(fetchUserData.data.message);
                }
            } catch (err) {
                openNotification('An error occurred while fetching student data. Please reload to try again.');
            }
        }
        let getAllVideosData = async () => {
            try {
                let fetchVideoData = await _fetch_videos_data();
                if (fetchVideoData.data.statusMessage === "success") {
                    setVideoData(fetchVideoData.data.message);
                }
                setLoadingData(false);
            } catch (err) {
                setLoadingData(false);
                openNotification('An error occurred while fetching quiz data. Please reload to try again.');
            }
        }
        getAllVideoPurchasesData();
        getAllUsersData();
        getAllVideosData();
    }, [])

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
        },
        {
            title: 'Category',
            className: 'column-money',
            dataIndex: 'category',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
        },
        {
            title: 'Created',
            dataIndex: 'dateAdded',
        }
    ];
    const data = videoData.slice(0, 5).map((note, index) => {
        return (
            {
                key: index,
                title: note.title,
                category: note?.videoCategory?.name,
                amount: note?.amount,
                dateAdded: DateTime.fromISO(note.createdAt).toFormat('MMMM dd, yyyy')
            }
        )
    });
    const secColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Email address',
            className: 'column-money',
            dataIndex: 'emailAddress'
        },
        {
            title: 'Phone number',
            className: 'column-money',
            dataIndex: 'phoneNumber'
        },
        {
            title: 'Gender',
            className: 'column-money',
            dataIndex: 'gender'
        },
        {
            title: 'Referral Code',
            dataIndex: 'referralCode',
        },
    ];
    const secData = userData.slice(0, 5).map((student, index) => {
        return {
            key: index,
            name: `${student.firstName} ${student.lastName}`,
            emailAddress: student.emailAddress,
            gender: student?.gender,
            phoneNumber: student.phoneNumber,
            referralCode: student.referralCode,
        }
    });
    return (
        <Spin spinning={loadingData} indicator={antIcon}>
            <div className="dashboard">
                <div>
                    <Sidenav />
                </div>
                <div className="main-display">
                    <div>
                        <TopBar />
                    </div>
                    <div>
                        <div className="main-display-content">
                            <div>
                                <h3 className="dashboard-title">Admin Dashboard</h3>
                                <div className="dashboard-grid-data-2">
                                    <div>
                                        <div className="content-box overview-summary mb-4">
                                            <div className="content-box-header">
                                                <h3>Panel Overview</h3>
                                                <div className="grid-3">
                                                    <div className="overview-summary-content">
                                                        <h3>{userData.length > 10 ? userData.length : `0${userData.length}`}</h3>
                                                        <p>Added Users</p>
                                                    </div>
                                                    <div className="overview-summary-content">
                                                        <h3>{videoData.length > 10 ? videoData.length : `0${videoData.length}`}</h3>
                                                        <p>Added Videos</p>
                                                    </div>
                                                    <div className="overview-summary-content">
                                                        <h3>{videoPurchaseData.length > 10 ? videoPurchaseData.length : `0${videoPurchaseData.length}`}</h3>
                                                        <p>All Video Purchases</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <div className="content-box side-story mb-4">
                                                <div className="content-box-header">
                                                    <div className="grid-flex">
                                                        <h3>Welcome Home,</h3>
                                                    </div>
                                                </div>
                                                <div className="content-box-body">
                                                    <div>
                                                        <p>Hey there, this is the admin dashboard that contains summaries to
                                                            student, teacher and quiz data. From here, you can find your way to
                                                            other sides of the page.
                                                        </p>
                                                        <p className="bg-grey">This panel is open to all administrators</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-box mb-4">
                                    <div className="content-box-header">
                                        <div className="grid-flex">
                                            <h3>Recently Added Users ({userData.length})</h3>
                                            <Link to={AllAppRoutes.all_students}>See More</Link>
                                        </div>
                                    </div>
                                    <Divider style={{ marginTop: 0 }} />
                                    <div className="content-box-body">
                                        <div>
                                            <Table
                                                columns={secColumns}
                                                dataSource={secData}
                                                bordered
                                                title={null}
                                                footer={null}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="content-box mb-4">
                                    <div className="content-box-header">
                                        <div className="grid-flex">
                                            <h3>Recently Added Videos ({videoData.length})</h3>
                                            <Link to={AllAppRoutes.all_students}>See More</Link>
                                        </div>
                                    </div>
                                    <Divider style={{ marginTop: 0 }} />
                                    <div className="content-box-body">
                                        <div>
                                            <Table
                                                columns={columns}
                                                dataSource={data}
                                                bordered
                                                title={null}
                                                footer={null}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <p>Hello there my good friend</p>
                <img src={_1} alt="" /> */}
                </div>
            </div>
        </Spin>
    )
}

export default Homepage;