import React, { useState, useEffect } from "react";

import { Input, Spin, Table, notification, Modal, Tabs, Rate } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import * as yup from 'yup';
import { DateTime } from "luxon";
import { Controller, set, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { _fetch_class_data, _fetch_single_video, _fetch_videos_data, _save_class_data } from "../utils/axios-routes";
import { Link, useNavigate, useSearchParams, useParams } from "react-router-dom";


import _1 from "../assets/images/_2.jpg";
import Sidenav from "../components/sidenav";
import TopBar from "../components/topbar";

const VideosAnalyticsPage = props => {

    const formValidator = yup.object().shape({
        className: yup.string().required('Please enter class name')
    })
    const { control, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(formValidator)
    });

    const antIcon = (<LoadingOutlined style={{ fontSize: 24 }} spin />);
    const Navigate = useNavigate();

    const [displayList, setDisplayList] = useState(true);
    const [savingData, setSavingData] = useState(false);
    const [fetchingData, setFetchingData] = useState(true);
    const [videoInfo, setVideoInfo] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [dateFilter, setDateFilter] = useState('all');
    const [actionFilter, setActionFilter] = useState('all');
    const [filteredVideo, setFilteredVideo] = useState([]);
    const [videoRatingsHistory, setVideoRatingsHistory] = useState([]);
    const [videoPurchasesHistory, setVideoPurchasesHistory] = useState([]);
    // const [urlKey, setUrlKey] = useSearchParams();
    // const newKey = new URLSearchParams(window.location.pathname)
    const urlKey = useParams();

    const openNotification = err => {
        notification.open({
            description: err
        });
    }

    useEffect(() => {
        if (localStorage.getItem('newDataSaved')) {
            openNotification('Data saved successfully.');
            localStorage.removeItem('newDataSaved');
        };
        let getAllVideoData = async () => {
            try {
                let fetchVideoData = await _fetch_single_video(urlKey.id);
                if (fetchVideoData.data.statusMessage === "success") {
                    setFetchingData(false);
                    setVideoInfo(fetchVideoData.data.message);
                    setFilteredVideo(fetchVideoData.data.message.userVideoViews);
                    let videoData = fetchVideoData.data.message;
                    setValue('videoTitle', videoData.title);
                    setValue('videoLength', videoData.videoLength + "mins");
                    setValue('videoLink', videoData.videoLink);
                    setValue('videoCategory', videoData?.videoCategory?.name);
                    setValue('instructorName', videoData?.instructorName);
                    setValue('videoCounter', videoData.counter ? videoData.counter : 0);
                    setValue('videoDescription', videoData.videoDescription);

                    setVideoRatingsHistory(videoData.videoRatings);
                    setVideoPurchasesHistory(videoData.videoPurchases);
                }
                setLoadingData(false)
            } catch (err) {
                openNotification('An error occurred while fetching videos data. Please reload to try again.');
                setLoadingData(false)
            }
        }
        getAllVideoData();
    }, [])

    const filterVideoAnalytics = (e) => {
        setActionFilter(e);
        let videoBoard = [];
        if (e === 'all') {
            videoBoard = videoInfo.userVideoViews;
        } else {
            videoInfo.userVideoViews.filter(video => {
                if (video[e]) {
                    videoBoard.push(video)
                }
            })
        }
        setFilteredVideo(videoBoard);
    }

    const today = new Date();
    const currentWeekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
    const currentWeekEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (6 - today.getDay()));
    const lastWeekStart = new Date(today.getFullYear(), today.getMonth(), ((today.getDate() - today.getDay()) - 7));
    const lastWeekEnd = new Date(today.getFullYear(), today.getMonth(), ((today.getDate() - today.getDay()) - 1));
    const currentYear = today.getFullYear();
    const filterDate = (actionFilter, e) => {
        let videoBoard = [];
        setDateFilter(e);
        setActionFilter(actionFilter);
        if (actionFilter === 'all') {
            videoBoard = videoInfo.userVideoViews;
        } else {
            videoInfo.userVideoViews.filter(video => {
                if (video[actionFilter]) {
                    videoBoard.push(video)
                }
            })
        }
        let newData = [];
        videoBoard.filter(item => {
            const itemDate = new Date(item.createdAt.split('T')[0]);
            const viewsDate = item.createdAt.split('-')[0];
            if (e === "this_week") {
                if (itemDate >= currentWeekStart && itemDate <= currentWeekEnd) {
                    newData.push(item)
                }
            } else if (e === "today") {
                if (itemDate === today) {
                    newData.push(item)
                }
            } else if (e === "last_week") {
                if (itemDate >= lastWeekStart && itemDate <= lastWeekEnd) {
                    newData.push(item)
                }
            } else if (e === "this_year") {
                if (viewsDate >= currentYear) {
                    newData.push(item)
                }
            } else {
                newData.push(item)
            }
        })
        setFilteredVideo(newData)
    }

    const columns = [
        {
            title: '',
            dataIndex: 'id',
        },
        {
            title: 'User',
            dataIndex: 'user',
        },
        {
            title: 'Email Address',
            className: 'column-money',
            dataIndex: 'emailAddress',
        },
        {
            title: 'Half',
            className: 'column-money',
            dataIndex: 'half',
        },
        {
            title: 'Full',
            dataIndex: 'full',
        },
        {
            title: 'Date Watched',
            dataIndex: 'dateAdded',
        },
        {
            title: 'Time Watched',
            dataIndex: 'time',
        },
    ];

    const data = filteredVideo?.map((user, index) => {
        return {
            key: index,
            id: index + 1,
            user: user.User.firstName + " " + user.User.lastName,
            emailAddress: user.User.emailAddress,
            half: user.half ? <p className="blue">true</p> : <p className="red">false</p>,
            full: user.full ? <p className="blue">true</p> : <p className="red">false</p>,
            dateAdded: DateTime.fromISO(user.createdAt.split('T')[0]).toFormat('MMMM dd, yyyy'),
            time: DateTime.fromISO(user.createdAt).toFormat('h:mma'),
        }
    })

    const videoPurchasesColumns = [
        {
            title: '',
            dataIndex: 'id',
        },
        {
            title: 'User',
            dataIndex: 'user',
        },
        {
            title: 'Email Address',
            className: 'column-money',
            dataIndex: 'emailAddress',
        },
        {
            title: 'Amount',
            className: 'column-money',
            dataIndex: 'amount',
        },
        {
            title: 'Transaction Id',
            dataIndex: 'transactionId',
        },
        {
            title: 'Date Watched',
            dataIndex: 'dateAdded',
        }
    ];

    const videoPurchasesData = videoPurchasesHistory.map((userName, index) => {
        return {
            key: index,
            id: index + 1,
            user: `${userName?.User?.firstName} ${userName?.User?.lastName}`,
            emailAddress: userName?.User?.emailAddress,
            amount: <span><span className="currency">NGN</span>{userName.amount}</span>,
            transactionId: userName?.transactionId,
            dateAdded: DateTime.fromISO(userName.createdAt).toFormat('MMMM dd, yyyy') ,
        }
    });

    const videoRatingsColumn = [
        {
            title: '',
            dataIndex: 'id',
        },
        {
            title: 'User',
            dataIndex: 'name',
        },
        {
            title: 'User email',
            dataIndex: 'email',
        },
        {
            title: 'Rating',
            dataIndex: 'rating',
        },
        {
            title: 'Message',
            dataIndex: 'message',
        },
        {
            title: 'Date Added',
            dataIndex: 'dateAdded',
        },
        // {
        //     title: 'Date Added',
        //     dataIndex: 'dateAdded',
        // }
    ];

    const videoRatingsData = videoRatingsHistory.map((userName, index) => {
        return {
            key: index,
            id: index + 1,
            name: `${userName?.User?.firstName} ${userName?.User?.lastName}`,
            email: userName?.User?.emailAddress,
            rating: <Rate defaultValue={userName.star} disabled />,
            message: <p style={{ width: '400px' }}>{userName.comment}</p>,
            dateAdded: DateTime.fromISO(userName.createdAt).toFormat('MMMM dd, yyyy'),
            // time: DateTime.fromISO(userName.createdAt),
        }
    });

    return (
        <Spin indicator={antIcon} spinning={loadingData}>
            <div>
                <div>
                    <Sidenav />
                </div>
                <div className="main-display">
                    <div>
                        <TopBar />
                    </div>
                    <div>
                        <div className="main-display-content">
                            <div>
                                <div className="grid-flex page-header">
                                    <h3>Videos</h3>
                                    <div>
                                        {
                                            displayList ?
                                                <button
                                                    onClick={() => setDisplayList(false)}
                                                    className="btn-blue">Add New Video</button>
                                                :
                                                <button
                                                    onClick={() => setDisplayList(true)}
                                                    className="btn-blue">See All Videos</button>
                                        }
                                    </div>
                                </div>
                                <div className="">
                                    <div>
                                        <div className="content-box mb-4">
                                            {
                                                displayList ?
                                                    <React.Fragment>
                                                        {
                                                            !fetchingData ?
                                                                <React.Fragment>
                                                                    <div className="content-box-header">
                                                                        <div className="grid-flex">
                                                                            <h3>Videos Analytics</h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="content-box-body">
                                                                        <div>
                                                                            <Tabs
                                                                                defaultActiveKey="1"
                                                                                type="card">
                                                                                <Tabs.TabPane tab="Basic Data" key={1}>
                                                                                    <div>
                                                                                        <form>
                                                                                            <div className="width-60">
                                                                                                <div className="form-flex-2">
                                                                                                    <div className="form-group">
                                                                                                        <label>Video Title</label>
                                                                                                        <Controller defaultValue="" name="videoTitle" control={control}
                                                                                                            render={({ field }) => (
                                                                                                                <Input {...field} style={{ height: '5rem' }} name="videoTitle" type="text" />
                                                                                                            )} />
                                                                                                    </div>
                                                                                                    <div className="form-group">
                                                                                                        <label>Total Views</label>
                                                                                                        <Controller defaultValue="" name="videoCounter" control={control}
                                                                                                            render={({ field }) => (
                                                                                                                <Input {...field} style={{ height: '5rem' }} name="videoCounter" type="text" />
                                                                                                            )} />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="form-flex-2">
                                                                                                    <div className="form-group">
                                                                                                        <label>Video Length</label>
                                                                                                        <Controller defaultValue="" name="videoLength" control={control}
                                                                                                            render={({ field }) => (
                                                                                                                <Input {...field} style={{ height: '5rem' }} name="videoLength" type="text" />
                                                                                                            )} />
                                                                                                    </div>
                                                                                                    <div className="form-group">
                                                                                                        <label>Video Category</label>
                                                                                                        <Controller defaultValue="" name="videoCategory" control={control}
                                                                                                            render={({ field }) => (
                                                                                                                <Input {...field} style={{ height: '5rem' }} name="videoCategory" type="text" />
                                                                                                            )} />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="form-group">
                                                                                                    <label>Video Link</label>
                                                                                                    <Controller defaultValue="" name="videoLink" control={control}
                                                                                                        render={({ field }) => (
                                                                                                            <Input {...field} style={{ height: '5rem' }} name="videoLink" type="text" />
                                                                                                        )} />
                                                                                                </div>
                                                                                                <div className="form-flex-2">
                                                                                                    <div className="form-group">
                                                                                                        <label>Instructor Name</label>
                                                                                                        <Controller defaultValue="" name="instructorName" control={control}
                                                                                                            render={({ field }) => (
                                                                                                                <Input {...field} style={{ height: '5rem' }} name="instructorName"
                                                                                                                    type="text" />
                                                                                                            )} />
                                                                                                    </div>
                                                                                                    <div className="form-group">
                                                                                                        <label>Instructor Image Link</label>
                                                                                                        <Controller defaultValue="" name="instructorImageLink" control={control}
                                                                                                            render={({ field }) => (
                                                                                                                <Input {...field} style={{ height: '5rem' }} name="instructorImageLink"
                                                                                                                    type="text" />
                                                                                                            )} />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="form-group">
                                                                                                    <label>Video Description</label>
                                                                                                    <Controller defaultValue="" name="videoDescription" control={control}
                                                                                                        render={({ field }) => (
                                                                                                            <Input.TextArea rows={15} {...field} name="videoDescription" type="text" />
                                                                                                        )} />
                                                                                                </div>

                                                                                                {
                                                                                                    !savingData ?
                                                                                                        <button className="btn-blue">Save Class</button>
                                                                                                        :
                                                                                                        <button className="btn-blue" disabled><LoadingOutlined style={{ fontSize: '2rem' }} /></button>
                                                                                                }
                                                                                            </div>
                                                                                        </form>
                                                                                    </div>
                                                                                </Tabs.TabPane>
                                                                                <Tabs.TabPane tab={`Video Views (${videoInfo?.counter})`} key={2}>
                                                                                    <div>
                                                                                        <h3
                                                                                            style={{ marginBottom: 20 }}
                                                                                        >Total Video Views: {videoInfo?.counter}</h3>
                                                                                    </div>
                                                                                    <div className="tag-box">
                                                                                        <button
                                                                                            onClick={() => filterDate('all', dateFilter)}
                                                                                            className={`tag ${actionFilter === "all" ? 'active' : ''}`}>All</button>
                                                                                        <button
                                                                                            onClick={() => filterDate('half', dateFilter)}
                                                                                            className={`tag ${actionFilter === "half" ? 'active' : ''}`}>Half Watched</button>
                                                                                        <button
                                                                                            onClick={() => filterDate('full', dateFilter)}
                                                                                            className={`tag ${actionFilter === "full" ? 'active' : ''}`}>Full Watched</button>
                                                                                    </div>
                                                                                    <div className="tag-box">
                                                                                        <button
                                                                                            onClick={() => filterDate(actionFilter, 'all')}
                                                                                            className={`tag ${dateFilter === "all" ? 'active' : ''}`}>All</button>
                                                                                        {/* <button
                                                                                            onClick={() => filterDate(actionFilter, 'today')}
                                                                                            className={`tag ${dateFilter === "today" ? 'active' : ''}`}>Today</button> */}
                                                                                        <button
                                                                                            onClick={() => filterDate(actionFilter, 'this_week')}
                                                                                            className={`tag ${dateFilter === "this_week" ? 'active' : ''}`}>This Week</button>
                                                                                        <button
                                                                                            onClick={() => filterDate(actionFilter, 'last_week')}
                                                                                            className={`tag ${dateFilter === "last_week" ? 'active' : ''}`}>Last Week</button>
                                                                                        <button
                                                                                            onClick={() => filterDate(actionFilter, 'this_year')}
                                                                                            className={`tag ${dateFilter === "this_year" ? 'active' : ''}`}>This Year</button>
                                                                                    </div>
                                                                                    <Table
                                                                                        columns={columns}
                                                                                        dataSource={data}
                                                                                        bordered
                                                                                        title={null}
                                                                                        footer={null}
                                                                                    />
                                                                                </Tabs.TabPane>
                                                                                <Tabs.TabPane tab={`Video Purchases (${videoPurchasesHistory.length})`} key={3}>
                                                                                    <div>
                                                                                        <Table
                                                                                            columns={videoPurchasesColumns}
                                                                                            dataSource={videoPurchasesData}
                                                                                            bordered
                                                                                            title={null}
                                                                                            footer={null}
                                                                                        />
                                                                                    </div>
                                                                                </Tabs.TabPane>
                                                                                <Tabs.TabPane tab={`Video Ratings (${videoRatingsHistory.length})`} key={4}>
                                                                                    <div>
                                                                                        <Table
                                                                                            columns={videoRatingsColumn}
                                                                                            dataSource={videoRatingsData}
                                                                                            bordered
                                                                                            title={null}
                                                                                            footer={null}
                                                                                        />
                                                                                    </div>
                                                                                </Tabs.TabPane>
                                                                            </Tabs>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                                :
                                                                <LoadingOutlined style={{ fontSize: '2rem' }} />
                                                        }
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                    </React.Fragment>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="delete-button" title={null} open={isModalOpen} footer={null}>
                    <h3>Are you sure you want to delete this class? This action can not be reversed!</h3>
                    <div className="grid-flex">
                        <div></div>
                        <div className="grid-button">
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="btn-default">Cancel</button>
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="btn-red">Delete</button>
                        </div>
                    </div>
                </Modal>
            </div>
        </Spin>
    )
}

export default VideosAnalyticsPage;