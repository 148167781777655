import axios from "axios";
import axiosCall from "./axiosCall"

export const _fake_get_call = () => {
    return setTimeout(() => { console.log('run me'); }, 4000)
}

export const _get_teachers_data = () => {
    return axiosCall.get('/teachers/get-all-teachers', {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _fetch_users_data = () => {
    return axiosCall.get('/admin/users/all', {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _fetch_videos_data = () => {
    return axiosCall.get('/admin/videos/all', {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}
export const _fetch_user_cart_data = userId => {
    return axiosCall.get(`/admin/user-cart/${userId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _give_user_money = req => {
    return axiosCall.post(`/admin/user-wallet-add`, req, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}
export const _give_user_token = req => {
    return axiosCall.post(`/admin/user-token-add`, req, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _fetch_single_user_data = userId => {
    return axiosCall.get(`/admin/user/${userId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _fetch_all_video_purchases = userId => {
    return axiosCall.get(`/admin/videos-purchases`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _change_user_verification = req => {
    return axiosCall.post(`/admin/update-user-verification`, req, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _change_user_free_access = req => {
    return axiosCall.post(`/admin/update-user-free-access`, req, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _save_teacher_data = req => {
    return axiosCall.post('/teachers/add-new-teacher', req, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _fetch_class_data = req => {
    return axiosCall.get('/classes/get-all-classes', {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _save_class_data = req => {
    return axiosCall.post('/classes/add-new-class', req, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _fetch_subjects_data = req => {
    return axiosCall.get('/subjects/get-all-subjects', {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _fetch_single_subject_data = req => {
    return axiosCall.get(`/subjects/get-subject-by-classid/${req}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _save_subjects_data = req => {
    return axiosCall.post('/subjects/add-new-subject', req, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _fetch_students_data = req => {
    return axiosCall.get('/students/get-all-students', {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _save_students_data = req => {
    return axiosCall.post('/students/add-new-student', req, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _fetch_notes_data = req => {
    return axiosCall.get('/notes/get-all-notes', {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _save_notes_data = req => {
    return axiosCall.post('/notes/add-new-note', req, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _fetch_quiz_data = req => {
    return axiosCall.get('/quiz/get-all-quizzes', {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _save_quiz_data = req => {
    return axiosCall.post('/quiz/add-new-quiz', req, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _fetch_term_data = req => {
    return axiosCall.get('/term/get-all-terms', {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _save_term_data = req => {
    return axiosCall.post('/term/add-new-term', req, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _send_24_hr_mail = req => {
    return axiosCall.post('/users/send-24-hr-mail', req, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _send_48_hr_mail = req => {
    return axiosCall.post('/users/send-48-hr-mail', req, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _fetch_single_video = req => {
    return axiosCall.get(`/admin/video/${req}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _fetch_all_user_cart_data = () => {
    return axiosCall.get(`/admin/user-cart/all`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _fetch_virtual_classes = req => {
    return axiosCall.get('/videos/fetch-all-virtual-classes', req, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _fetch_physical_classes = req => {
    return axiosCall.get('/videos/fetch-all-physical-classes', req, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _fetch_products_classes = req => {
    return axiosCall.get('/videos/fetch-all-products', req, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _fetch_products_orders = req => {
    return axiosCall.get('/users/fetch-all-product-orders', req, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _fetch_course_orders = req => {
    return axiosCall.get('/users/fetch-all-course-orders', req, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}