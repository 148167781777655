import React, { useState, useEffect } from "react";

import { Input, Spin, Table, Tabs, notification, Modal, Rate, Divider, Radio } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import * as yup from 'yup';
import { DateTime } from "luxon";
import { Controller, set, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { _change_user_free_access, _change_user_verification, _fetch_class_data, _fetch_single_user_data, _fetch_term_data, _fetch_users_data, _give_user_money, _give_user_token, _save_class_data } from "../../utils/axios-routes";
import { Link, useNavigate, useParams } from "react-router-dom";


import _1 from "../../assets/images/_2.jpg";
import Sidenav from "../../components/sidenav";
import TopBar from "../../components/topbar";
import { _save_term_data } from "../../utils/axios-routes";

const UserDetail = () => {

    const formValidator = yup.object().shape({
        termName: yup.string().required('Please enter term name')
    })
    const { control, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(formValidator)
    });

    const { control: controlUpdateWallet, handleSubmit: handleSubmitWallet,
        formState: { errors: errorsUpdateWallet }, setValue: setUpdateWallet } = useForm({
            resolver: yupResolver(formValidator)
        });
    const { control: controlUpdateToken, handleSubmit: handleSubmitToken,
        formState: { errors: errorsUpdateToken }, setValue: setUpdateToken } = useForm({
            resolver: yupResolver(formValidator)
        });

    const antIcon = (<LoadingOutlined style={{ fontSize: 24 }} spin />);
    const Navigate = useNavigate();
    const urlParam = useParams();

    const [displayList, setDisplayList] = useState(true);
    const [savingData, setSavingData] = useState(false);
    const [fetchingData, setFetchingData] = useState(true);
    const [userData, setUserData] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [userTransactionHistory, setUserTransactionHistory] = useState([]);
    const [userVideoViews, setUserVideoViews] = useState([]);
    const [userVideoPurchaseHistory, setUserVideoPurchaseHistory] = useState([]);
    const [userCart, setUserCart] = useState([]);
    const [userSignInHistory, setUserSignInHistory] = useState([]);
    const [userRatingsHistory, setUserRatingsHistory] = useState([]);
    const [actionAmount, setActionAmount] = useState(0);
    const [tokenActionAmount, setTokenActionAmount] = useState(0);
    const [freeAccess, setFreeAccess] = useState(false);
    const [userVerification, setUserVerification] = useState(false);


    const openNotification = (type, err) => {
        notification.open({
            type,
            description: err
        });
    }

    useEffect(() => {
        let userId = urlParam.userId;
        let getAllUserData = async () => {
            try {
                let fetchUserData = await _fetch_single_user_data(userId);
                if (fetchUserData.data.statusMessage === "success") {
                    setFetchingData(false);
                    setUserData(fetchUserData.data.message);
                    let userDetails = fetchUserData.data.message;
                    setValue('firstName', userDetails.firstName);
                    setValue('lastName', userDetails.lastName);
                    setValue('verified', userDetails.verified ? 'true' : 'false');
                    setValue('emailAddress', userDetails.emailAddress);
                    setValue('phoneNumber', userDetails.phoneNumber);
                    setValue('referralCode', userDetails.referralCode);
                    setValue('createdAt', DateTime.fromISO(userDetails.createdAt).toFormat('MMMM dd, yyyy'));
                    setValue('userId', userDetails._id);

                    setUpdateWallet('walletBalance', userDetails?.userWallet?.balance);
                    setUpdateToken('tokenBalance', userDetails?.userWallet?.tokens);

                    setUserTransactionHistory(userDetails.walletTransactions);
                    setUserVideoViews(userDetails.userVideoViews);
                    setUserVideoPurchaseHistory(userDetails.videoPurchases);
                    setUserCart(userDetails.userCarts);
                    setUserSignInHistory(userDetails.SignInData);
                    setUserRatingsHistory(userDetails.videoRatings);
                    setUserVerification(userDetails.verified);
                    setFreeAccess(userDetails.freeAccess);
                }
                setLoadingData(false)
            } catch (err) {
                openNotification('error', 'An error occurred while fetching user data. Please reload to try again.');
                setLoadingData(false)
            }
        }
        getAllUserData();
    }, [])

    const userTransactionColumn = [
        {
            title: '',
            dataIndex: 'id',
        },
        {
            title: '',
            dataIndex: 'ref',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
        },
        {
            title: 'Wallet Balance',
            dataIndex: 'walletBalance',
        },
        {
            title: 'Token Balance',
            dataIndex: 'tokenBalance',
        },
        {
            title: 'Wallet Type',
            dataIndex: 'walletType',
        },
        {
            title: 'Status',
            dataIndex: 'status',
        },
        {
            title: 'transactionKey',
            dataIndex: 'transactionKey',
        },
        {
            title: 'Date added',
            dataIndex: 'dateAdded',
        }
    ];
    const userTransactionData = userTransactionHistory.map((userName, index) => {
        return {
            key: index,
            id: index + 1,
            ref: userName.ref,
            amount: <p><span className="currency">NGN</span>{userName.amount}</p>,
            status: userName.status === "completed" ? <span className="green">{userName.status}</span> : <span className="red">{userName.status}</span>,
            walletBalance: <p><span className="currency">NGN</span>{userName.walletBalance}</p>,
            tokenBalance: userName.tokenBalance,
            walletType: userName.walletType,
            transactionKey: userName.inHouseTransactionKey,
            dateAdded: DateTime.fromISO(userName.createdAt).toFormat('MMMM dd, yyyy'),
            editAction: <button onClick={() => setIsModalOpen(true)} className="btn-blue">View User</button>,
        }
    });

    const userVideoViewsColumn = [
        {
            title: '',
            dataIndex: 'id',
        },
        {
            title: 'Video Title',
            dataIndex: 'title',
        },
        {
            title: 'Half',
            dataIndex: 'half',
        },
        {
            title: 'Full',
            dataIndex: 'full',
        },
        {
            title: 'Video Category',
            dataIndex: 'category',
        },
        {
            title: 'Date',
            dataIndex: 'dateAdded',
        },
        {
            title: 'Time',
            dataIndex: 'time',
        }
    ];
    const userVideoViewsData = userVideoViews.map((userName, index) => {
        return {
            key: index,
            id: index + 1,
            title: userName?.video?.title,
            half: userName.half === true ? 'true' : 'false',
            full: userName.full === true ? 'true' : 'false',
            category: userName?.video?.videoCategory?.name,
            dateAdded: DateTime.fromISO(userName.createdAt).toFormat('MMMM dd, yyyy'),
            time: DateTime.fromISO(userName.createdAt).toLocaleString(DateTime.TIME_WITH_SECONDS),
        }
    });

    const userVideoPurchaseColumn = [
        {
            title: '',
            dataIndex: 'id',
        },
        {
            title: 'Video Title',
            dataIndex: 'title',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
        },
        {
            title: 'Date',
            dataIndex: 'dateAdded',
        },
        {
            title: 'Time',
            dataIndex: 'time',
        }
    ];
    const userVideoPurchaseData = userVideoPurchaseHistory.map((userName, index) => {
        return {
            key: index,
            id: index + 1,
            title: userName?.videoName,
            discount: <p><span className="currency">NGN</span>{userName.discount}</p>,
            amount: <p><span className="currency">NGN</span>{userName.amount}</p>,
            dateAdded: DateTime.fromISO(userName.createdAt).toFormat('MMMM dd, yyyy'),
            time: DateTime.fromISO(userName.createdAt).toLocaleString(DateTime.TIME_WITH_SECONDS),
        }
    });

    const userCartColumn = [
        {
            title: '',
            dataIndex: 'id',
        },
        {
            title: 'Video Title',
            dataIndex: 'title',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
        },
        {
            title: 'Date Added To Cart',
            dataIndex: 'dateAdded',
        }
    ];
    const userCartData = userCart.map((userName, index) => {
        return {
            key: index,
            id: index + 1,
            title: userName?.video?.title,
            amount: <p><span className="currency">NGN</span>{userName?.video?.amount}</p>,
            discount: <p><span className="currency">NGN</span>{userName?.video?.discount}</p>,
            dateAdded: DateTime.fromISO(userName.createdAt).toFormat('MMMM dd, yyyy'),
            // time: DateTime.fromISO(userName.createdAt).toLocaleString(DateTime.TIME_WITH_SECONDS),
        }
    });

    const userSignInColumn = [
        {
            title: '',
            dataIndex: 'id',
        },
        {
            title: 'Login date',
            dataIndex: 'logInDate',
        },
        {
            title: 'Login Time',
            dataIndex: 'logInTime',
        },
        {
            title: 'Logout Time',
            dataIndex: 'logoutDate',
        },
        {
            title: 'Logout Time',
            dataIndex: 'logoutTime',
        }
    ];

    const userSignInData = userSignInHistory.map((userName, index) => {
        return {
            key: index,
            id: index + 1,
            logInDate: DateTime.fromISO(userName.createdAt).toFormat('MMMM dd, yyyy'),
            logInTime: DateTime.fromISO(userName.createdAt).toLocaleString(DateTime.TIME_WITH_SECONDS),
            logoutDate: userName.logOut ? DateTime.fromISO(userName.updatedAt).toFormat('MMMM dd, yyyy') : '-',
            logoutTime: userName.logOut ? DateTime.fromISO(userName.updatedAt).toLocaleString(DateTime.TIME_WITH_SECONDS) : '-',
            // time: DateTime.fromISO(userName.createdAt),
        }
    });

    const userRatingsColumn = [
        {
            title: '',
            dataIndex: 'id',
        },
        {
            title: 'Video',
            dataIndex: 'videoTitle',
        },
        {
            title: 'Rating',
            dataIndex: 'rating',
        },
        {
            title: 'Message',
            dataIndex: 'message',
        },
        {
            title: 'Date Added',
            dataIndex: 'dateAdded',
        },
        // {
        //     title: 'Date Added',
        //     dataIndex: 'dateAdded',
        // }
    ];

    const userRatingsData = userRatingsHistory.map((userName, index) => {
        return {
            key: index,
            id: index + 1,
            videoTitle: userName?.video?.title,
            rating: <Rate defaultValue={userName.star} disabled />,
            message: <p style={{ width: '400px' }}>{userName.comment}</p>,
            dateAdded: DateTime.fromISO(userName.createdAt).toFormat('MMMM dd, yyyy'),
            // time: DateTime.fromISO(userName.createdAt),
        }
    });


    const giveUserMoney = async req => {
        setLoadingData(true);
        let dataToSend = {
            actionType: req,
            userId: userData._id,
            amount: actionAmount
        }
        try {
            let giveUser = await _give_user_money(dataToSend);
            if (giveUser.data.statusMessage === "success") {
                // setLoadingData(false);
                openNotification('success', 'User updated successfully');
                localStorage.setItem('newDataSaved', true);
                Navigate(0);
            } else {
                setLoadingData(false);
                openNotification('error', giveUser.data.summary);
            }
        } catch (err) {
            setLoadingData(false);
            openNotification('error', 'An error occurred while trying to update wallet. Please try again.');
        }
    }
    const giveUserToken = async req => {
        setLoadingData(true);
        let dataToSend = {
            actionType: req,
            userId: userData._id,
            amount: tokenActionAmount
        }
        try {
            let giveUser = await _give_user_token(dataToSend);
            if (giveUser.data.statusMessage === "success") {
                // setLoadingData(false);
                openNotification('success', 'User updated successfully');
                localStorage.setItem('newDataSaved', true);
                Navigate(0);
            } else {
                setLoadingData(false);
                openNotification('error', giveUser.data.summary);
            }
        } catch (err) {
            setLoadingData(false);
            openNotification('error', 'An error occurred while trying to update wallet. Please try again.');
        }
    }
    const updateUserVerification = async req => {
        setLoadingData(true);
        let dataToSend = {
            verified: userVerification,
            userId: userData._id,
        }
        try {
            let giveUser = await _change_user_verification(dataToSend);
            if (giveUser.data.statusMessage === "success") {
                // setLoadingData(false);
                openNotification('success', 'User updated successfully');
                localStorage.setItem('newDataSaved', true);
                Navigate(0);
            } else {
                setLoadingData(false);
                openNotification('error', giveUser.data.summary);
            }
        } catch (err) {
            setLoadingData(false);
            openNotification('error', 'An error occurred while trying to update user. Please try again.');
        }
    }
    const changeFreeAccess = e => {
        setFreeAccess(e.target.value);
    }
    const changeUserVerification = e => {
        setUserVerification(e.target.value);
    }
    const giveUserFreeAccess = async req => {
        setLoadingData(true);
        let dataToSend = {
            freeAccess,
            userId: userData._id,
        }
        try {
            let giveUser = await _change_user_free_access(dataToSend);
            if (giveUser.data.statusMessage === "success") {
                // setLoadingData(false);
                openNotification('success', 'User updated successfully');
                localStorage.setItem('newDataSaved', true);
                Navigate(0);
            } else {
                setLoadingData(false);
                openNotification('error', giveUser.data.summary);
            }
        } catch (err) {
            setLoadingData(false);
            openNotification('error', 'An error occurred while trying to update user. Please try again.');
        }
    }
    return (
        <Spin indicator={antIcon} spinning={loadingData}>
            <div>
                <div>
                    <Sidenav />
                </div>
                <div className="main-display">
                    <div>
                        <TopBar />
                    </div>
                    <div>
                        <div className="main-display-content">
                            <div>
                                <div className="grid-flex page-header">
                                    <h3>User</h3>
                                    <div>
                                    </div>
                                </div>
                                <div className="">
                                    <div>
                                        <div className="content-box mb-4">
                                            <React.Fragment>
                                                {
                                                    !fetchingData ?
                                                        <React.Fragment>
                                                            <div>
                                                                <Tabs type="card">
                                                                    <Tabs.TabPane tab="Basic settings" key="1">
                                                                        <div className="user-data">
                                                                            <div>
                                                                                <form>
                                                                                    <div className="form-flex-2">
                                                                                        <div className="form-group">
                                                                                            <label>First name</label>
                                                                                            <Controller name="firstName" control={control}
                                                                                                render={({ field }) => (
                                                                                                    <Input disabled type="text" {...field}
                                                                                                        style={{ height: '5rem' }} />
                                                                                                )} />
                                                                                        </div>
                                                                                        <div className="form-group">
                                                                                            <label>Last name</label>
                                                                                            <Controller name="lastName" control={control}
                                                                                                render={({ field }) => (
                                                                                                    <Input disabled type="text" {...field}
                                                                                                        style={{ height: '5rem' }} />
                                                                                                )} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="form-flex-2">
                                                                                        <div className="form-group">
                                                                                            <label>Email address</label>
                                                                                            <Controller name="emailAddress" control={control}
                                                                                                render={({ field }) => (
                                                                                                    <Input disabled type="text" {...field}
                                                                                                        style={{ height: '5rem' }} />
                                                                                                )} />
                                                                                        </div>
                                                                                        <div className="form-group">
                                                                                            <label>Phone number</label>
                                                                                            <Controller name="phoneNumber" control={control}
                                                                                                render={({ field }) => (
                                                                                                    <Input disabled type="text" {...field}
                                                                                                        style={{ height: '5rem' }} />
                                                                                                )} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="form-flex-2">
                                                                                        <div className="form-group">
                                                                                            <label>Verified</label>
                                                                                            <Controller name="verified" control={control}
                                                                                                render={({ field }) => (
                                                                                                    <Input disabled type="text" {...field}
                                                                                                        style={{ height: '5rem' }} />
                                                                                                )} />
                                                                                        </div>
                                                                                        <div className="form-group">
                                                                                            <label>Referral Code</label>
                                                                                            <Controller name="referralCode" control={control}
                                                                                                render={({ field }) => (
                                                                                                    <Input disabled type="text" {...field}
                                                                                                        style={{ height: '5rem' }} />
                                                                                                )} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="form-flex-2">
                                                                                        <div className="form-group">
                                                                                            <label>Signup Date</label>
                                                                                            <Controller name="createdAt" control={control}
                                                                                                render={({ field }) => (
                                                                                                    <Input disabled type="text" {...field}
                                                                                                        style={{ height: '5rem' }} />
                                                                                                )} />
                                                                                        </div>
                                                                                        <div className="form-group">
                                                                                            <label>User Id</label>
                                                                                            <Controller name="userId" control={control}
                                                                                                render={({ field }) => (
                                                                                                    <Input disabled type="text" {...field}
                                                                                                        style={{ height: '5rem' }} />
                                                                                                )} />
                                                                                        </div>
                                                                                    </div>
                                                                                </form>
                                                                                <Divider />
                                                                                <form>
                                                                                    <div>
                                                                                        <div>
                                                                                            <Radio.Group buttonStyle="solid" onChange={changeFreeAccess} value={freeAccess}>
                                                                                                <Radio.Button value={true}>Free Access</Radio.Button>
                                                                                                <Radio.Button value={false}>Remove Free Access</Radio.Button>
                                                                                            </Radio.Group>
                                                                                        </div>
                                                                                        <div>
                                                                                            <button className="btn-blue"
                                                                                                style={{ padding: '8px 15px', fontSize: '1.3rem', marginTop: 20 }}
                                                                                                onClick={() => giveUserFreeAccess()}>Update verification</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </form>
                                                                                <Divider />
                                                                                <form>
                                                                                    <div>
                                                                                        <div>
                                                                                            <Radio.Group buttonStyle="solid" onChange={changeUserVerification} value={userVerification}>
                                                                                                <Radio.Button value={true}>Verify</Radio.Button>
                                                                                                <Radio.Button value={false}>Remove Verification</Radio.Button>
                                                                                            </Radio.Group>
                                                                                        </div>
                                                                                        <div>
                                                                                            <button className="btn-blue"
                                                                                                style={{ padding: '8px 15px', fontSize: '1.3rem', marginTop: 20 }}
                                                                                                onClick={() => updateUserVerification()}>Update verification</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    </Tabs.TabPane>
                                                                    <Tabs.TabPane tab="Wallet Data" key="2">
                                                                        <form>
                                                                            <div className="form-group">
                                                                                <label>Wallet Balance</label>
                                                                                <div className="form-flex-2">
                                                                                    <div className="">
                                                                                        <Controller name="walletBalance" control={controlUpdateWallet}
                                                                                            render={({ field }) => (
                                                                                                <Input type="text" disabled {...field}
                                                                                                    style={{ height: '5rem' }} />
                                                                                            )} />
                                                                                    </div>
                                                                                    <div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                        <Divider />
                                                                        <div style={{ width: '50%' }}>
                                                                            <Input type="text" value={actionAmount}
                                                                                onChange={e => setActionAmount(e.target.value)}
                                                                                style={{ height: '5rem' }} />
                                                                            <div style={{ display: 'flex', gridGap: 20, marginTop: 30 }}>
                                                                                <button
                                                                                    onClick={() => giveUserMoney('add')}
                                                                                    className="btn-blue">Add to User Wallet</button>
                                                                                <button
                                                                                    onClick={() => giveUserMoney('subtract')}
                                                                                    className="btn-red">Subtract from User Wallet</button>
                                                                            </div>
                                                                        </div>
                                                                        <Divider />
                                                                        <form>
                                                                            <div className="form-group">
                                                                                <label>Token Balance</label>
                                                                                <div className="form-flex-2">
                                                                                    <div className="">
                                                                                        <Controller name="tokenBalance" control={controlUpdateToken}
                                                                                            render={({ field }) => (
                                                                                                <Input type="text" {...field} disabled
                                                                                                    style={{ height: '5rem' }} />
                                                                                            )} />
                                                                                    </div>
                                                                                    <div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                        <Divider />
                                                                        <div style={{ width: '50%' }}>
                                                                            <Input type="text" value={tokenActionAmount}
                                                                                onChange={e => setTokenActionAmount(e.target.value)}
                                                                                style={{ height: '5rem' }} />
                                                                            <div style={{ display: 'flex', gridGap: 20, marginTop: 30 }}>
                                                                                <button
                                                                                    onClick={() => giveUserToken('add')}
                                                                                    className="btn-blue">Add to User Token</button>
                                                                                <button
                                                                                    onClick={() => giveUserToken('subtract')}
                                                                                    className="btn-red">Subtract from User Token</button>
                                                                            </div>
                                                                        </div>
                                                                    </Tabs.TabPane>
                                                                    <Tabs.TabPane tab="Transaction History" key="3">
                                                                        <div>
                                                                            <Table
                                                                                columns={userTransactionColumn}
                                                                                dataSource={userTransactionData}
                                                                                bordered
                                                                                title={null}
                                                                                footer={null}
                                                                            />
                                                                        </div>
                                                                    </Tabs.TabPane>
                                                                    <Tabs.TabPane tab="Video Purchase History" key="4">
                                                                        <div>
                                                                            <Table
                                                                                columns={userVideoPurchaseColumn}
                                                                                dataSource={userVideoPurchaseData}
                                                                                bordered
                                                                                title={null}
                                                                                footer={null}
                                                                            />
                                                                        </div>
                                                                    </Tabs.TabPane>
                                                                    <Tabs.TabPane tab="Video Views History" key="5">
                                                                        <div>
                                                                            <Table
                                                                                columns={userVideoViewsColumn}
                                                                                dataSource={userVideoViewsData}
                                                                                bordered
                                                                                title={null}
                                                                                footer={null}
                                                                            />
                                                                        </div>
                                                                    </Tabs.TabPane>
                                                                    <Tabs.TabPane tab="User Cart" key="6">
                                                                        <div>
                                                                            <Table
                                                                                columns={userCartColumn}
                                                                                dataSource={userCartData}
                                                                                bordered
                                                                                title={null}
                                                                                footer={null}
                                                                            />
                                                                        </div>
                                                                    </Tabs.TabPane>
                                                                    <Tabs.TabPane tab="Sign In History" key="7">
                                                                        <div>
                                                                            <Table
                                                                                columns={userSignInColumn}
                                                                                dataSource={userSignInData}
                                                                                bordered
                                                                                title={null}
                                                                                footer={null}
                                                                            />
                                                                        </div>
                                                                    </Tabs.TabPane>
                                                                    <Tabs.TabPane tab="User Rating History" key="8">
                                                                        <div>
                                                                            <Table
                                                                                columns={userRatingsColumn}
                                                                                dataSource={userRatingsData}
                                                                                bordered
                                                                                title={null}
                                                                                footer={null}
                                                                            />
                                                                        </div>
                                                                    </Tabs.TabPane>
                                                                </Tabs>
                                                            </div>
                                                        </React.Fragment>
                                                        :
                                                        <LoadingOutlined style={{ fontSize: '2rem' }} />
                                                }
                                            </React.Fragment>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="delete-button" title={null} open={isModalOpen} footer={null}>
                    <h3>Are you sure you want to delete this user? This action can not be reversed!</h3>
                    <div className="grid-flex">
                        <div></div>
                        <div className="grid-button">
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="btn-default">Cancel</button>
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="btn-red">Delete</button>
                        </div>
                    </div>
                </Modal>
            </div>
        </Spin>
    )
}

export default UserDetail;