import React, { useState, useEffect } from "react";

import { Input, Spin, Table, notification, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import * as yup from 'yup';
import { DateTime } from "luxon";
import { Controller, set, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { _fetch_class_data, _fetch_term_data, _fetch_users_data, _save_class_data } from "../../utils/axios-routes";
import { Link, useNavigate } from "react-router-dom";


import _1 from "../../assets/images/_2.jpg";
import Sidenav from "../../components/sidenav";
import TopBar from "../../components/topbar";
import { _save_term_data } from "../../utils/axios-routes";

const Users = () => {
    const formValidator = yup.object().shape({
        termName: yup.string().required('Please enter term name')
    })
    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(formValidator)
    });

    const antIcon = (<LoadingOutlined style={{ fontSize: 24 }} spin />);
    const Navigate = useNavigate();

    const [displayList, setDisplayList] = useState(true);
    const [savingData, setSavingData] = useState(false);
    const [fetchingData, setFetchingData] = useState(true);
    const [userData, setUserData] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [filteredUsers, setFilteredUsers] = useState([]);

    const openNotification = err => {
        notification.open({
            description: err
        });
    }

    useEffect(() => {
        if (localStorage.getItem('newDataSaved')) {
            openNotification('Data saved successfully.');
            localStorage.removeItem('newDataSaved');
        };
        let getAllUserData = async () => {
            try {
                let fetchUserData = await _fetch_users_data();
                if (fetchUserData.data.statusMessage === "success") {
                    setFetchingData(false);
                    setUserData(fetchUserData.data.message);
                    setFilteredUsers(fetchUserData.data.message);
                }
                setLoadingData(false)
            } catch (err) {
                openNotification('An error occurred while fetching user data. Please reload to try again.');
                setLoadingData(false)
            }
        }
        getAllUserData();
    }, [])

    const filteredUsersByFreeUsers = () => {
        let newUsers = [];
        userData.find((users) => {
            if (users.freePlanUsed) {
                newUsers.push(users)
            }
        })
        setFilteredUsers(newUsers);
    };

    const seeAllUsers = () => setFilteredUsers(userData);

    const filteredUsersByActivePlan = () => {
        userData.filter((users) => {
            let newUsers = [];
            userData.find((users) => {
                if (users.freePlanUsed) {
                    newUsers.push(users)
                }
            })
            setFilteredUsers(newUsers);
        })
    };

    const columns = [
        {
            title: '',
            dataIndex: 'id',
        },
        {
            title: 'Full Name',
            dataIndex: 'fullName',
        },
        {
            title: 'Email address',
            dataIndex: 'emailAddress',
        },
        {
            title: 'Verified',
            dataIndex: 'verified',
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
        },
        {
            title: 'Date added',
            dataIndex: 'dateAdded',
        },
        {
            title: '',
            dataIndex: 'editAction',
        }
    ];
    const data = filteredUsers.map((userName, index) => {
        return {
            key: index,
            id: index + 1,
            fullName: userName.firstName + ' ' + userName.lastName,
            emailAddress: userName.emailAddress,
            verified: userName.verified === true ? <span className="green">true</span> : <span className="red">false</span>,
            phoneNumber: userName.phoneNumber,
            freePlan: userName.freePlanUsed ? 'used' : 'not used',
            activePlan: 0,
            dateAdded: DateTime.fromISO(userName.createdAt).toFormat('MMMM dd, yyyy'),
            editAction: <Link to={`/user/${userName._id}`} className="btn-blue">View User</Link>,
            // editAction: <button onClick={() => setIsModalOpen(true)} className="btn-blue">View User</button>,
        }
    });

    const saveClass = async e => {
        setErrorMessage('');
        setSavingData(true);
        try {
            let saveNewTerm = await _save_term_data(e);
            if (saveNewTerm.data.statusMessage === "success") {
                setSavingData(false);
                localStorage.setItem('newDataSaved', true);
                Navigate(0);
            } else {
                let errorMessage = saveNewTerm.data.summary === "Term already saved. Please check and try again" ?
                    saveNewTerm.data.summary : "An error occurred while trying to save Term. Please try again.";
                setSavingData(false);
                setErrorMessage(errorMessage);
                openNotification(errorMessage);
            }
        } catch (err) {
            console.log(err)
            setSavingData(false);
            setErrorMessage('An error occurred while trying to save Term. Please try again.');
            openNotification('An error occurred while trying to save Term. Please try again.');
        }
    }
    return (
        <Spin indicator={antIcon} spinning={loadingData}>
            <div>
                <div>
                    <Sidenav />
                </div>
                <div className="main-display">
                    <div>
                        <TopBar />
                    </div>
                    <div>
                        <div className="main-display-content">
                            <div>
                                <div className="grid-flex page-header">
                                    <h3>User</h3>
                                    <div>
                                    </div>
                                </div>
                                <div className="">
                                    <div>
                                        <div className="content-box mb-4">
                                            <React.Fragment>
                                                {
                                                    !fetchingData ?
                                                        <React.Fragment>
                                                            <div className="content-box-header">
                                                                <div className="grid-flex">
                                                                </div>
                                                            </div>
                                                            <div className="content-box-body mt-5">
                                                                <div>
                                                                    <Table
                                                                        columns={columns}
                                                                        dataSource={data}
                                                                        bordered
                                                                        title={null}
                                                                        footer={null}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                        :
                                                        <LoadingOutlined style={{ fontSize: '2rem' }} />
                                                }
                                            </React.Fragment>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="delete-button" title={null} open={isModalOpen} footer={null}>
                    <h3>Are you sure you want to delete this user? This action can not be reversed!</h3>
                    <div className="grid-flex">
                        <div></div>
                        <div className="grid-button">
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="btn-default">Cancel</button>
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="btn-red">Delete</button>
                        </div>
                    </div>
                </Modal>
            </div>
        </Spin>
    )
}

export default Users;