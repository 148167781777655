import React, { useState, useEffect } from "react";

import { Input, Spin, Table, notification, Modal, Checkbox } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import * as yup from 'yup';
import { DateTime } from "luxon";
import { Controller, set, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import {
    _fetch_class_data, _fetch_term_data, _fetch_users_data,
    _save_class_data, _send_24_hr_mail, _send_48_hr_mail
} from "../utils/axios-routes";
import { Link, useNavigate } from "react-router-dom";


import _1 from "../assets/images/_2.jpg";
import Sidenav from "../components/sidenav";
import TopBar from "../components/topbar";
import { _save_term_data } from "../utils/axios-routes";

const TrialUsers = () => {

    const formValidator = yup.object().shape({
        termName: yup.string().required('Please enter term name')
    })
    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(formValidator)
    });

    const antIcon = (<LoadingOutlined style={{ fontSize: 24 }} spin />);
    const Navigate = useNavigate();

    const [displayList, setDisplayList] = useState(true);
    const [savingData, setSavingData] = useState(false);
    const [fetchingData, setFetchingData] = useState(true);
    const [userData, setUserData] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [mailRecipients, setMailRecipients] = useState([]);
    const [action, setAction] = useState('24hr')

    const openNotification = err => {
        notification.open({
            description: err
        });
    }

    useEffect(() => {
        if (localStorage.getItem('newDataSaved')) {
            openNotification('Mail sent successfully.');
            localStorage.removeItem('newDataSaved');
        };
        let getAllUserData = async () => {
            try {
                let fetchUserData = await _fetch_users_data();
                if (fetchUserData.data.statusMessage === "success") {
                    let fetchedUserData = fetchUserData.data.message;
                    let newUserData = [];
                    fetchedUserData.forEach(user => {
                        if (user.freePlanUsed) {
                            let userData = {
                                firstName: user.firstName,
                                fullName: user.firstName + ' ' + user.lastName,
                                emailAddress: user.emailAddress,
                                freePlan: user.freePlanUsed ? 'used' : 'not used',
                            };
                            user.productPlanPurchases.forEach((order) => {
                                if (order.free) {
                                    userData.productPlanPurchases = order
                                }
                            });
                            newUserData.push(userData)
                        }

                        // if (user.freePlanUsed) newUserData.push(userData)
                    });
                    setFetchingData(false);
                    setUserData(newUserData);
                    setFilteredUsers(newUserData);
                }
                setLoadingData(false)
            } catch (err) {
                openNotification('An error occurred while fetching user data. Please reload to try again.');
                setLoadingData(false)
            }
        }
        getAllUserData();
    }, [])

    const userMailListAction = newUserData => {
        let userMailData = mailRecipients;
        let emailIndex = userMailData.indexOf(newUserData);
        if (emailIndex !== -1) {
            userMailData.splice(emailIndex, 1);
        } else {
            userMailData.push(newUserData)
        }
        setMailRecipients([...userMailData])
    }

    const filteredUsersByFreeUsers = () => {
        let newUsers = [];
        userData.find((users) => {
            if (users.freePlanUsed) {
                newUsers.push(users)
            }
        })
        setFilteredUsers(newUsers);
    };

    const seeAllUsers = () => setFilteredUsers(userData);

    const filteredUsersByActivePlan = () => {
        userData.filter((users) => {
            let newUsers = [];
            userData.find((users) => {
                if (users.freePlanUsed) {
                    newUsers.push(users)
                }
            })
            setFilteredUsers(newUsers);
        })
    };

    const columns = [
        {
            title: '',
            dataIndex: 'id',
        },
        {
            title: '',
            dataIndex: "userMailAction"
        },
        {
            title: 'Full Name',
            dataIndex: 'fullName',
        },
        {
            title: 'Email address',
            dataIndex: 'emailAddress',
        },
        // {
        //     title: 'Free plan',
        //     dataIndex: 'freePlan',
        // },
        {
            title: 'Date added',
            dataIndex: 'dateAdded',
        },
        {
            title: 'Expiration',
            dataIndex: 'expiration',
        },
        {
            title: '',
            dataIndex: 'edit24Action',
        },
        {
            title: '',
            dataIndex: 'edit48Action',
        }
    ];
    const data = filteredUsers.map((userName, index) => {
        // let newUserData = { emailAddress: userName.emailAddress, firstName: userName.firstName }
        let newUserData = `${userName.emailAddress}-|||-${userName.firstName}`;
        return {
            key: index,
            id: index + 1,
            userMailAction: <Checkbox
                checked={mailRecipients.includes(newUserData) ? true : false}
                onChange={() => userMailListAction(newUserData)} />,
            fullName: userName.fullName,
            emailAddress: userName.emailAddress,
            freePlan: userName.freePlanUsed ? 'used' : 'not used',
            activePlan: 0,
            // expiration: DateTime.fromISO(userName.createdAt).toFormat('MMMM dd, yyyy'),
            expiration: DateTime.fromISO(userName?.productPlanPurchases?.expiryDate.split(' ')[0]).toFormat('MMMM dd, yyyy'),
            dateAdded: DateTime.fromISO(userName?.productPlanPurchases?.createdAt).toFormat('MMMM dd, yyyy'),
            // dateAdded: DateTime.fromISO(userName.createdAt).toFormat('MMMM dd, yyyy'),
            // edit24Action: <button onClick={() => setIsModalOpen(true)} className="btn-blue">Send 24 Hrs Mail</button>,
            // edit48Action: <button disabled onClick={() => setIsModalOpen(true)} className="btn-blue">Send 48 Hrs Mail</button>,
        }
    });

    const sendMailToUser = async () => {
        setLoadingData(true);
        try {
            let userMailData = await action === "48hr" ? _send_48_hr_mail(mailRecipients) : _send_24_hr_mail(mailRecipients);
            if (userMailData.data.statusMessage === "success") {
                localStorage.setItem('newDataSaved', true);
                Navigate(0);
            } else {
                setLoadingData(false);
                openNotification('An error occurred while sending mail. Please reload to try again.');
            }
        } catch (err) {
            openNotification('An error occurred while sending mail. Please reload to try again.');
            setLoadingData(false);
        }
    }

    const openMailModal = e => {
        if (mailRecipients.length) {
            setAction(e);
            setIsModalOpen(true);
        } else {
            openNotification('Please add atleast 1 email address to mail list')
        }
    }

    const saveClass = async e => {
        setErrorMessage('');
        setSavingData(true);
        try {
            let saveNewTerm = await _save_term_data(e);
            if (saveNewTerm.data.statusMessage === "success") {
                setSavingData(false);
                localStorage.setItem('newDataSaved', true);
                Navigate(0);
            } else {
                let errorMessage = saveNewTerm.data.summary === "Term already saved. Please check and try again" ?
                    saveNewTerm.data.summary : "An error occurred while trying to save Term. Please try again.";
                setSavingData(false);
                setErrorMessage(errorMessage);
                openNotification(errorMessage);
            }
        } catch (err) {
            console.log(err)
            setSavingData(false);
            setErrorMessage('An error occurred while trying to save Term. Please try again.');
            openNotification('An error occurred while trying to save Term. Please try again.');
        }
    }
    return (
        <Spin indicator={antIcon} spinning={loadingData}>
            <div>
                <div>
                    <Sidenav />
                </div>
                <div className="main-display">
                    <div>
                        <TopBar />
                    </div>
                    <div>
                        <div className="main-display-content">
                            <div>
                                <div className="grid-flex page-header">
                                    <h3>User</h3>
                                </div>
                                <div className="">
                                    <div>
                                        <div className="content-box mb-4">
                                            {
                                                displayList ?
                                                    <React.Fragment>
                                                        {
                                                            !fetchingData ?
                                                                <React.Fragment>
                                                                    <div className="content-box-header">
                                                                        <div className="grid-flex">
                                                                            <h3>New Free Trial Users</h3>
                                                                        </div>
                                                                        <div className="button-grid">
                                                                            <button onClick={() => openMailModal('24hr')} className="btn-blue">Send 24 Hrs Mail ({mailRecipients.length})</button>
                                                                            <button onClick={() => openMailModal('48hr')} className="btn-blue">Send 48 Hrs Mail ({mailRecipients.length})</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="content-box-body mt-5">
                                                                        <div>
                                                                            <Table
                                                                                columns={columns}
                                                                                dataSource={data}
                                                                                bordered
                                                                                title={null}
                                                                                footer={null}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                                :
                                                                <LoadingOutlined style={{ fontSize: '2rem' }} />
                                                        }
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <div className="content-box-header">
                                                            <div className="grid-flex">
                                                                <h3>Add New User</h3>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="width-60">
                                                                {
                                                                    errorMessage ?
                                                                        <div className="error-block">
                                                                            <p className="error-message">{errorMessage}</p>
                                                                        </div> : ''
                                                                }
                                                                <form onSubmit={handleSubmit(saveClass)}>
                                                                    <div className="form-group">
                                                                        <label htmlFor="termName">Term name</label>
                                                                        <Controller control={control} defaultValue="" name="termName"
                                                                            render={({ field }) => (
                                                                                <Input
                                                                                    style={{ height: '5rem' }} {...field}
                                                                                    name="termName" id="termName" type="text" />
                                                                            )} />
                                                                        {errors.termName && <p className="error-message">{errors.termName.message}</p>}
                                                                    </div>
                                                                    {
                                                                        !savingData ?
                                                                            <button className="btn-blue">Save Term</button>
                                                                            :
                                                                            <button className="btn-blue" disabled><LoadingOutlined style={{ fontSize: '2rem' }} /></button>
                                                                    }
                                                                </form>
                                                                {/* <img src={_1} alt="" /> */}
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="delete-button" title={null} open={isModalOpen} footer={null}>
                    <h3>Are you sure you want to send this mail to these users? This action can not be reversed!</h3>
                    <div className="grid-flex">
                        <div></div>
                        <div className="grid-button">
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="btn-default">Cancel</button>
                            <button
                                onClick={() => sendMailToUser()}
                                className="btn-red">Send Mail</button>
                        </div>
                    </div>
                </Modal>
            </div>
        </Spin >
    )
}

export default TrialUsers;