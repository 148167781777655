import React, { useState, useEffect } from "react";

import { Input, Spin, Table, notification, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import * as yup from 'yup';
import { DateTime } from "luxon";
import { Controller, set, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { _fetch_all_user_cart_data, _fetch_class_data, _fetch_term_data, _fetch_users_data, _save_class_data } from "../utils/axios-routes";
import { Link, useNavigate } from "react-router-dom";


import _1 from "../assets/images/_2.jpg";
import Sidenav from "../components/sidenav";
import TopBar from "../components/topbar";
import { _save_term_data } from "../utils/axios-routes";
import { _fetch_all_video_purchases } from "../utils/axios-routes";

const UserCarts = () => {
    const formValidator = yup.object().shape({
        termName: yup.string().required('Please enter term name')
    })
    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(formValidator)
    });

    const antIcon = (<LoadingOutlined style={{ fontSize: 24 }} spin />);
    const Navigate = useNavigate();

    const [displayList, setDisplayList] = useState(true);
    const [savingData, setSavingData] = useState(false);
    const [fetchingData, setFetchingData] = useState(true);
    const [cartData, setCartData] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loadingData, setLoadingData] = useState(true);

    const openNotification = err => {
        notification.open({
            description: err
        });
    }

    useEffect(() => {
        if (localStorage.getItem('newDataSaved')) {
            openNotification('Data saved successfully.');
            localStorage.removeItem('newDataSaved');
        };
        let getAllUserData = async () => {
            try {
                let fetchUserData = await _fetch_all_user_cart_data();
                console.log(fetchUserData)
                if (fetchUserData.data.statusMessage === "success") {
                    setFetchingData(false);
                    setCartData(fetchUserData.data.message);
                }
                setLoadingData(false)
            } catch (err) {
                openNotification('An error occurred while fetching user data. Please reload to try again.');
                setLoadingData(false)
            }
        }
        getAllUserData();
    }, [])

    const columns = [
        {
            title: '',
            dataIndex: 'id',
        },
        {
            title: 'Video Title',
            dataIndex: 'title',
        },
        {
            title: 'User',
            dataIndex: 'userName',
        },
        {
            title: 'User Email',
            dataIndex: 'emailAddress',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
        }, {
            title: 'Date added',
            dataIndex: 'dateAdded',
        },
        // {
        //     title: '',
        //     dataIndex: 'editAction',
        // }
    ];
    const data = cartData.map((cart, index) => {
        return {
            key: index,
            id: index + 1,
            userName: cart?.User.firstName + " " + cart?.User.lastName,
            emailAddress: cart?.User.emailAddress,
            title: cart?.video?.title,
            amount: <p><span className="currency">NGN</span>{cart?.video?.amount}</p>,
            dateAdded: DateTime.fromISO(cart.createdAt).toFormat('MMMM dd, yyyy'),
            editAction: <button to={`/user/${cart._id}`} className="btn-red">Delete</button>,
        }
    });

    return (
        <Spin indicator={antIcon} spinning={loadingData}>
            <div>
                <div>
                    <Sidenav />
                </div>
                <div className="main-display">
                    <div>
                        <TopBar />
                    </div>
                    <div>
                        <div className="main-display-content">
                            <div>
                                <div className="grid-flex page-header">
                                    <h3>Video Purchases</h3>
                                    <div>
                                    </div>
                                </div>
                                <div className="">
                                    <div>
                                        <div className="content-box mb-4">
                                            <React.Fragment>
                                                {
                                                    !fetchingData ?
                                                        <React.Fragment>
                                                            <div className="content-box-header">
                                                                <div className="grid-flex">
                                                                    <h3></h3>
                                                                </div>
                                                            </div>
                                                            <div className="content-box-body mt-5">
                                                                <div>
                                                                    <Table
                                                                        columns={columns}
                                                                        dataSource={data}
                                                                        bordered
                                                                        title={null}
                                                                        footer={null}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                        :
                                                        <LoadingOutlined style={{ fontSize: '2rem' }} />
                                                }
                                            </React.Fragment>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="delete-button" title={null} open={isModalOpen} footer={null}>
                    <h3>Are you sure you want to delete this user? This action can not be reversed!</h3>
                    <div className="grid-flex">
                        <div></div>
                        <div className="grid-button">
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="btn-default">Cancel</button>
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="btn-red">Delete</button>
                        </div>
                    </div>
                </Modal>
            </div>
        </Spin>
    )
}

export default UserCarts;