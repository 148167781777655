import React from "react";
import { Link, NavLink } from "react-router-dom";

import AllAppRoutes from '../utils/routes';

import { ReactComponent as Dashboard } from "../assets/images/archway.svg";
import { ReactComponent as LogOut } from "../assets/images/toggle.svg";
import { ReactComponent as Quizzes } from "../assets/images/input-device.svg";
import { ReactComponent as Notes } from "../assets/images/document.svg";
import { ReactComponent as Subjects } from "../assets/images/archive.svg";
import { ReactComponent as Teachers } from "../assets/images/setting.svg";
import { ReactComponent as Users } from "../assets/images/profile.svg";
import { ReactComponent as Classes } from "../assets/images/tv.svg";
import { ReactComponent as ExternalNavLink } from "../assets/images/external-link.svg";
// import {ReactComponent as Subjects} from "../assets/images/archive.svg";


const Sidenav = () => {
    return (
        <div className="sidenav">
            <div>
                <div className="side-title">
                    <h2>DANCERAPY PORTAL</h2>
                </div>
                <div>
                    <h4><Link to={AllAppRoutes.dashboard}>Admin Dashboard</Link></h4>
                </div>
                <div className="sidenav-cover">
                    <ul>
                        {/* <li>
                            <NavLink
                                className={({ isActive }) => isActive ? 'active-link' : ''}
                                to={AllAppRoutes.dashboard}><Classes /> <span>Dashboard</span></NavLink>
                        </li> */}
                        <li>
                            <NavLink
                                className={({ isActive }) => isActive ? 'active-link' : ''}
                                to={AllAppRoutes.all_users}><Users /> <span>Users</span></NavLink>
                        </li>
                        <li>
                            <NavLink
                                className={({ isActive }) => isActive ? 'active-link' : ''}
                                to={AllAppRoutes.all_weekly_users}><Users /> <span>Weekly Trial Users</span></NavLink>
                        </li>
                        <li>
                            <NavLink
                                className={({ isActive }) => isActive ? 'active-link' : ''}
                                to={AllAppRoutes.all_videos}><Classes /> <span>Videos</span></NavLink>
                        </li>
                        <li>
                            <NavLink
                                className={({ isActive }) => isActive ? 'active-link' : ''}
                                to={AllAppRoutes.all_video_purchases}><Quizzes /><span>Video Purchases</span></NavLink>
                        </li>
                        <li>
                            <NavLink
                                className={({ isActive }) => isActive ? 'active-link' : ''}
                                to={AllAppRoutes.user_cart}><Quizzes /><span>All User Cart</span></NavLink>
                        </li>
                        {/* <li>
                            <NavLink
                                className={({ isActive }) => isActive ? 'active-link' : ''}
                                to={AllAppRoutes.all_virtual_classes}><Subjects /><span>Virtual Classes</span></NavLink>
                        </li>
                        <li>
                            <NavLink
                                className={({ isActive }) => isActive ? 'active-link' : ''}
                                to={AllAppRoutes.all_physical_classes}><Quizzes /><span>Physical Classes</span></NavLink>
                        </li>
                        <li>
                            <NavLink
                                className={({ isActive }) => isActive ? 'active-link' : ''}
                                to={AllAppRoutes.all_products}><Quizzes /><span>Product</span></NavLink>
                        </li>
                        <li>
                            <NavLink
                                className={({ isActive }) => isActive ? 'active-link' : ''}
                                to={AllAppRoutes.all_product_orders}><Notes /> <span>Product Orders</span></NavLink>
                        </li>
                        <li>
                            <NavLink
                                className={({ isActive }) => isActive ? 'active-link' : ''}
                                to={AllAppRoutes.all_physical_orders}><Notes /> <span>Physical Orders</span></NavLink>
                        </li> */}
                        {/* <li>
                            <NavLink
                                className={({ isActive }) => isActive ? 'active-link' : ''}
                                to={AllAppRoutes.all_students}><Users /><span>Merch</span></NavLink>
                        </li>
                        <li>
                            <NavLink
                                className={({ isActive }) => isActive ? 'active-link' : ''}
                                to={AllAppRoutes.all_teachers}><Teachers /> <span>Merch Orders</span></NavLink>
                        </li> */}
                    </ul>
                </div>
                <div className="bottom-bar">
                    <NavLink
                        className={({ isActive }) => isActive ? 'active-link' : ''}
                        to=""><ExternalNavLink /> View Site</NavLink>
                    <NavLink
                        className={({ isActive }) => isActive ? 'active-link' : ''}
                        to=""><LogOut /> Log Out</NavLink>
                </div>
            </div>
        </div>
    )
}

export default Sidenav;