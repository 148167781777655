import React from "react";

import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import AllAppRoutes from "./utils/routes";

import Homepage from "./pages/homepage";
import Users from "./pages/users/users";
import VirtualClasses from "./pages/virtual-classes";
import SignInPage from "./pages";
import Signout from "./pages/Signout";
import PhysicalClasses from "./pages/physical-classes";
import Products from "./pages/products";
import TrialUsers from "./pages/trial-users";
import VideosPage from "./pages/videos";
import AllProductsOrders from "./pages/product-orders";
import AllPhysicalOrders from "./pages/physical-orders";
import VideosAnalyticsPage from "./pages/video-analytics";
import UserDetail from "./pages/users/user-detail";
import VideoPurchases from "./pages/videotransactions";
import UserCarts from "./pages/cart";

const AppRoutes = () => {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<SignInPage />} />
                    <Route path="/signout" element={<Signout />} />
                    <Route path="/*" element={<Navigate to={AllAppRoutes.dashboard} />} />

                    <Route path={AllAppRoutes.dashboard} element={<Homepage />} />
                    <Route path={AllAppRoutes.all_users} element={<Users />} />
                    <Route path={AllAppRoutes.all_video_purchases} element={<VideoPurchases />} />
                    <Route path={AllAppRoutes.user_detail} element={<UserDetail />} />
                    <Route path={AllAppRoutes.user_cart} element={<UserCarts />} />
                    <Route path={AllAppRoutes.all_weekly_users} element={<TrialUsers />} />
                    <Route path={AllAppRoutes.all_videos} element={<VideosPage />} />
                    <Route path={AllAppRoutes.all_physical_orders} element={<AllPhysicalOrders />} />
                    <Route path={AllAppRoutes.all_product_orders} element={<AllProductsOrders />} />
                    <Route path={AllAppRoutes.all_products} element={<Products />} />
                    <Route path={AllAppRoutes.all_virtual_classes} element={<VirtualClasses />} />
                    <Route path={AllAppRoutes.all_physical_classes} element={<PhysicalClasses />} />

                    <Route path={AllAppRoutes.video_analytics} element={<VideosAnalyticsPage />} />
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default AppRoutes;